import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import JobData from '../../data/jobs.json';
import FieldInJobData from '../../data/job-in-field.json';
import { Link } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import RegionStudy from '../components/RegionStudy.jsx';
import GeoSchoolSearch from '../components/GeoSchoolSearch.jsx';
import OrientaCta from '../components/OrientaCta.jsx';
import { Navigation, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';

const DettaglioPercorso = ({ selectedField }) => {
    const [selectedRegion, setSelectedRegion] = useState(20);
    const [isCollegeDegree, setIsCollegeDegree] = useState(false);
    const [isIts, setIsIts] = useState(false);
    const [isTechnicalInstitute, setIsTechnicalInstitute] = useState(false);
    const [isProTraining, setIsProTraining] = useState(false);
    const [isHighSchool, setIsHighSchool] = useState(false);
    const placesSwiperRef = useRef(null);
    const nextButtonRef = useRef(null);
    const prevButtonRef = useRef(null);
    const location = useLocation();

    const subfieldsLength = selectedField.subFields.length || 0;
    const jobData = JobData;
    const fieldInJobData = FieldInJobData;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    var FieldTypes;
    (function (FieldTypes) {
        FieldTypes["PROFESSIONAL_TRAINING"] = "PROFESSIONAL_TRAINING";
        FieldTypes["HIGHSCHOOLS"] = "HIGHSCHOOLS";
        FieldTypes["TECHNICAL_INSTITUTES"] = "TECHNICAL_INSTITUTES";
        FieldTypes["COLLEGES_DEGREES"] = "COLLEGES_DEGREES";
        FieldTypes["ITS"] = "ITS";
    })(FieldTypes || (FieldTypes = {}));

    useEffect(() => {
        if (selectedField.type === FieldTypes.COLLEGES_DEGREES) {
            setIsCollegeDegree(true);
            setIsIts(false);
            setIsTechnicalInstitute(false);
            setIsProTraining(false);
            setIsHighSchool(false);
        } else if (selectedField.type === FieldTypes.ITS) {
            setIsCollegeDegree(false);
            setIsIts(true);
            setIsTechnicalInstitute(false);
            setIsProTraining(false);
            setIsHighSchool(false);
        } else if (selectedField.type === FieldTypes.TECHNICAL_INSTITUTES) {
            setIsCollegeDegree(false);
            setIsIts(false);
            setIsTechnicalInstitute(true);
            setIsProTraining(false);
            setIsHighSchool(false);
        } else if (selectedField.type === FieldTypes.PROFESSIONAL_TRAINING) {
            setIsCollegeDegree(false);
            setIsIts(false);
            setIsTechnicalInstitute(false);
            setIsProTraining(true);
            setIsHighSchool(false);
        } else if (selectedField.type === FieldTypes.HIGHSCHOOLS) {
            setIsCollegeDegree(false);
            setIsIts(false);
            setIsTechnicalInstitute(false);
            setIsProTraining(false);
            setIsHighSchool(true);
        } else {
            setIsCollegeDegree(false);
            setIsIts(false);
            setIsTechnicalInstitute(false);
            setIsProTraining(false);
            setIsHighSchool(false);
        }
    }, [selectedField]);

    useEffect(() => {
        const accordionTrg = document.querySelector('a.custom-info-accordion-trg');
        const accordion = document.querySelector('div.custom-info-accordion');
        if (accordionTrg && accordion) {
            const handleClick = (e) => {
                e.preventDefault();
                accordion.classList.toggle('open');
                window.scrollTo(0, 0);
            };
            accordionTrg.addEventListener('click', handleClick);
            return () => {
                accordionTrg.removeEventListener('click', handleClick);
            };
        }
    }, []);


    /*
    * RELATED JOBS - corrisponde a RELATED STUDY PATHS in DettaglioProfessione.jsx
    */
    const [relatedJobs, setRelatedJobs] = useState([]);
    const [relatedJobsToShow, setRelatedJobsToShow] = useState([20]);

    useEffect(() => {
        // tutti gli elementi di fieldInJobData che hanno fieldId uguale al codice del percorso corrente, includeInJob true e hasAssunzioni true
        const jobInFieldToShow = fieldInJobData.filter((jobField) => jobField.fieldId === parseInt(selectedField.code) && jobField.includeInJob && jobField.hasAssunzioni);
        // const orderedByPercentage = jobInFieldToShow.sort((a, b) => parseInt(b.percentage) - parseInt(a.percentage)); // ordina per percentage decrescente
        const orderedByAssunzioni = jobInFieldToShow.sort((a, b) => parseInt(b.assunzioni) - parseInt(a.assunzioni)); // ordina per assunzioni decrescente
        const retrivedJobs = [];

        orderedByAssunzioni.forEach((jobField) => {
            const currentJobId = jobField.jobId;
            const jobInfo = JobData.find((job) => parseInt(job.fourDigitCode) === currentJobId);
            if (!jobInfo) return;

            // SCOMMENTA QUESTO BLOCCO SE SI VUOLE INCROCIARE I DATI DELLA CONDIZIONE DEL NUOVO EXCEL CON IL "includeJobInField" DEL FIELD CORRENTE
            // const isInJobSummaries = selectedField.jobsSummaries.find((job) => job.jobName === jobInfo.jobName);
            // if (isInJobSummaries && selectedField.jobsSummaries.find((job) => job.jobName === jobInfo.jobName && job.includeJobInField === false)) return; // incrocia il includeJobInField vecchio con quello del nuovo excel

            retrivedJobs.push({
                jobName: jobInfo.jobName,
                slug: jobInfo.slug,
            });
        });



        retrivedJobs.length > relatedJobsToShow
            ? setRelatedJobs(retrivedJobs.slice(0, relatedJobsToShow))
            : setRelatedJobs(retrivedJobs);
    }, [relatedJobsToShow, selectedField]);

    // useEffect(() => {
    //     const retrivedJobs = []
    //     selectedField.jobsSummaries.forEach((job) => {
    //         if (job.includeJobInField) {
    //             retrivedJobs.push(job);
    //         }
    //     });
    //     setRelatedJobs(retrivedJobs);
    // }, []);


    /*
    * WHERE TO STUDY ---- PLACES BY REGION
    */
    const [filteredPlaces, setFilteredPlaces] = useState([]);

    const RegionsIndex = {
        PIEMONTE: 0,
        VALLE_DAOSTA: 1,
        LOMBARDIA: 2,
        TRENTINO_ALTO_ADIGE: 3,
        VENETO: 4,
        FRIULI_VENEZIA_GIULIA: 5,
        LIGURIA: 6,
        EMILIA_ROMAGNA: 7,
        TOSCANA: 8,
        UMBRIA: 9,
        MARCHE: 10,
        LAZIO: 11,
        ABRUZZO: 12,
        MOLISE: 13,
        CAMPANIA: 14,
        PUGLIA: 15,
        BASILICATA: 16,
        CALABRIA: 17,
        SICILIA: 18,
        SARDEGNA: 19,
        TUTTA_ITALIA: 20
    };

    const placesByRegion = () => {
        if (isCollegeDegree) {
            return (selectedField.colleges ?? []).map((college) => ({
                region: college.region,
                key: `${college.headquarterDescription}-${college.operativeName}-${college.courseName}-${college.schoolCode}-${college.numericCode}`,
                courseName: college.courseName,
                operativeName: college.operativeName,
                location: `${college.city} (${college.province})`,
                level: college.numericCode.includes("L-") || college.numericCode.includes("L/") ? "Laurea Triennale" : "Laurea Magistrale",
                websiteUrl: college.website
            })).filter((college) => selectedRegion === RegionsIndex.TUTTA_ITALIA ? true : college.region === selectedRegion);
        } else {
            return (selectedField.itsInstitutes ?? []).map((institute) => ({
                region: institute.region,
                key: `${institute.region}-${institute.institute}-${institute.headquarter}`,
                courseName: institute.institute,
                operativeName: institute.institute,
                location: `${institute.headquarter} (${institute.province})`,
                websiteUrl: institute.website
            })).filter((institute) => selectedRegion === RegionsIndex.TUTTA_ITALIA ? true : institute.region === selectedRegion);
        }
    };

    useEffect(() => {
        setFilteredPlaces(placesByRegion());
    }, [selectedRegion, isCollegeDegree, isIts, isTechnicalInstitute, isProTraining, selectedField]);

    useEffect(() => {
        if (nextButtonRef.current && prevButtonRef.current) {
            setTimeout(() => {
                const swiperInstance = placesSwiperRef.current.swiper;
                swiperInstance.params.navigation.nextEl = nextButtonRef.current;
                swiperInstance.params.navigation.prevEl = prevButtonRef.current;
                swiperInstance.navigation.init();
                swiperInstance.navigation.update();
            }, 1000);
        }
    }, [filteredPlaces, filteredPlaces.length, location, placesSwiperRef, nextButtonRef, prevButtonRef]);

    return (
        <>
            <div className="container py-3">
                <div className="row">
                    <a href='/percorsi-di-studio' className="col-12 d-flex align-items-center">
                        <Icon.ArrowLeftShort size={30} className='text-primary mw-icon me-2 ms-n1' />
                        <span className='text-uppercase fs-6 fw-bold'>Indietro</span>
                    </a>
                </div>
            </div>
            <div className='bg-primary page-title-row position-sticky' style={{ top: "4.5rem", zIndex: 4, boxShadow: "-4px 14px 20px 0px #00000026" }}>
                <div className="container py-4">
                    <div className="row">
                        <div className="col-10">
                            <h1 className="mb-0 text-white">{selectedField.title}</h1>
                        </div>
                        <div className='col-2 d-flex align-items-center justify-content-end'>
                            <a href='#' className='border border-white custom-info-accordion-trg rouded-50 rounded-circle text-decoration-none text-white' title="Informazioni sui percorsi">
                                i
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container custom-info-accordion'>
                <div className='row'>
                    <div className='col-12'>
                        <p className='alert alert-info fw-normal mt-5'>
                            All'interno di questa pagina puoi approfondire l'indirizzo di studio che hai selezionato.<br /><br />
                            In Descrizione puoi trovare più informazioni su quell'indirizzo e le eventuali specializzazioni che ne fanno parte.<br />
                            In Sbocchi professionali puoi scoprire quali sono le principali aree professionali verso le quali si può orientare chi ha completato questo indirizzo di studi.<br />
                            Infine, nella scheda Dove si può studiare trovi informazioni utili sugli enti, le università, le scuole che includono quell'indirizzo di studio nella loro offerta formativa. Sul tuo territorio, o a livello nazionale.<br /><br />
                            Le informazioni possono essere differenti e più o meno approfondite a seconda della tipologia di indirizzo selezionato e fanno riferimento a dati del 2022.
                        </p>
                    </div>
                </div>
            </div>
            <div className="container mb-5 mt-5 pt-4">
                <div className="row mb-4">
                    <div className="col-12">
                        <h2 className="h3 fw-bold mb-4">Descrizione</h2>
                        <p className='fs-4 lh-base mb-0'>
                            {isCollegeDegree
                                ? (<>Fanno parte di questo indirizzo diversi corsi di laurea (il nome del corso a cui ci si può effettivamente iscrivere):</>)
                                : (<>Fanno parte di questo percorso i seguenti indirizzi:</>)
                            }
                        </p>
                    </div>
                </div>
                <div className={`row justify-content-start d-flex align-items-stretch pt-5 pt-lg-0`}>
                    <>
                        {console.log(selectedField)}
                        {selectedField.subFields.map((subField, index) => (
                            <div className='col-12 col-lg-4 mb-5 mb-lg-4 mt-lg-4' key={index}>
                                <div className='card border-0 bg-100 h-100 rounded-3'>
                                    <div className='card-body d-flex flex-column justify-content-between'>
                                        <div className='main field'>
                                            <h3 className='h5 fw-bold mb-2'>{subField.title}</h3>
                                        </div>
                                        {subField.description && (
                                            <div className='info'>
                                                <p className='mb-4'>
                                                    {/* {subField.type ?? subField.description} */}
                                                    {subField.description}
                                                </p>
                                            </div>
                                        )}
                                        {subField.url && (
                                            <a href={subField.url} target='_blank' rel='noreferrer' className='text-uppercase text-decoration-none text-primary fw-semibold'>
                                                Scopri di più
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>
                </div>
            </div>
            {isProTraining && (
                <div className='bg-primary'>
                    <div className='container py-5'>
                        <div className='row'>
                            <div className='col-12 col-md-9'>
                                <h2 className='h3 fw-bold mb-4 text-white'>Scopri di più</h2>
                                <p className='fs-5 lh-base text-white pe-0 pe-md-4 pe-lg-0'>Per informazioni più approfondite, visita il sito del <span className='fw-bold'>Ministero dell'Istruzione e del Merito</span></p>
                            </div>
                            <div className='col-12 col-md-3 d-flex align-items-center justify-content-center justify-content-md-end mt-4 mt-md-0'>
                                <a href='https://www.miur.gov.it/web/guest/istruzione-e-formazione-professionale' target='_blank' rel='noreferrer' className='btn btn-white rounded-0 fw-700 fs-5 text-uppercase px-4 py-3 lh-sm'>Visita il sito</a>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isCollegeDegree && (
                <div className='bg-primary'>
                    <div className='container py-5'>
                        <div className='row'>
                            <div className='col-12 col-md-9'>
                                <h2 className='h3 fw-bold mb-4 text-white'>Scopri di più</h2>
                                <p className='fs-5 lh-base text-white pe-0 pe-md-4 pe-lg-0'>Per informazioni più approfondite, visita il sito di <span className='fw-bold'>Universitaly</span></p>
                            </div>
                            <div className='col-12 col-md-3 d-flex align-items-center justify-content-center justify-content-md-end mt-4 mt-md-0'>
                                <a href='https://www.universitaly.it/' target='_blank' rel='noreferrer' className='btn btn-white rounded-0 fw-700 fs-5 text-uppercase px-4 py-3 lh-sm'>Visita il sito</a>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isTechnicalInstitute && (
                <div className='bg-primary'>
                    <div className='container py-5'>
                        <div className='row'>
                            <div className='col-12 col-lg-5 col-xl-6'>
                                <h2 className='h3 fw-bold mb-4 text-white'>Scopri di più</h2>
                                <p className='fs-5 lh-base text-white pe-0 pe-lg-4 pe-xl-0'>Per informazioni più approfondite, visita il sito del <span className='fw-bold'>Ministero dell'Istruzione e del Merito</span></p>
                            </div>
                            <div className='col-12 col-lg-7 col-xl-6 d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-lg-end mt-4 mt-lg-0'>
                                <a href='https://unica.istruzione.gov.it/it/orientamento/guida-alla-scelta/dal-sistema-integrato-0-6-anni-al-secondo-ciclo-di-istruzione/scuola-secondaria-di-secondo-grado/professionali'
                                    target='_blank' rel='noreferrer' className='btn btn-white rounded-0 fw-700 fs-5 text-start text-uppercase px-4 py-3 lh-sm me-md-3 mb-4 mb-md-0'>
                                    Istituti professionali
                                </a>
                                <a href='https://unica.istruzione.gov.it/it/orientamento/guida-alla-scelta/dal-sistema-integrato-0-6-anni-al-secondo-ciclo-di-istruzione/scuola-secondaria-di-secondo-grado/tecnici'
                                    target='_blank' rel='noreferrer' className='btn btn-white rounded-0 fw-700 fs-5 text-start text-uppercase px-4 py-3 lh-sm'>
                                    Istituti tecnici
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {relatedJobs.length > 0 && (
                <div className='container my-5 py-5'>
                    <div className='row'>
                        <div className='col-12'>
                            <h2 className='h3 fw-bold mb-4'>Sbocchi professionali</h2>
                            {isHighSchool ? (
                                <p className='fs-4 lh-base'>
                                    Questo percorso di studi non ha come obiettivo principale quello di prepararti direttamente per il mondo del lavoro, anche se le imprese comunque ricercano e inseriscono nei propri organici persone con questo diploma. Piuttosto, ti fornisce una base di strumenti e competenze che possono essere affinate attraverso studi successivi, come Laurea o ITS Academy.
                                    <br /><br />
                                    Approfondisci i percorsi di studio post-diploma attraverso la ricerca di excelsiorienta, oppure scopri di più sulle professioni per le quali le imprese maggiormente richiedono persone con questo diploma,
                                    secondo i dati raccolti dal Sistema Informativo Excelsior, alla <a href='https://excelsior.unioncamere.net/' target='_blank' rel='noreferrer'>sezione "Pubblicazioni" del portale Excelsior</a>.
                                </p>
                            ) : (
                                <p className='fs-4 lh-base'>
                                    Esplora le principali professioni per le quali le imprese dell'industria e dei servizi richiedono questo percorso di studi, secondo i dati raccolti dal Sistema Informativo
                                    Excelsior. Questa lista non è esaustiva, puoi trovare maggiori approfondimenti alla <a href='https://excelsior.unioncamere.net/' target='_blank' rel='noreferrer'>sezione "Pubblicazioni" del portale Excelsior</a>.
                                </p>
                            )}
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-12 mt-5">
                            <h3 className="h5 fw-bold mb-4">Le principali professioni</h3>
                        </div>
                        <div className='col-12'>
                            {relatedJobs.map((job, index) => (
                                <Link
                                    key={index}
                                    className='text-decoration-none w-100 d-flex align-items-center justify-content-between mb-4 mb-lg-4 fw-semibold'
                                    to={`/professioni/${job.slug}`}
                                >
                                    <p className='mb-0 fs-4 pe-4'>{job.jobName}</p>
                                    <Icon.ArrowRightShort size={40} className='text-primary mw-icon' />
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            )}
            {(isCollegeDegree || isIts) && (
                <>
                    <div className='position-relative'>
                        <div className='container mt-5 py-5'>
                            <div className='row mb-4'>
                                <div className='col-12 col-md-6 col-lg-8 col-xl-9'>
                                    <h2 className='h3 fw-bold mb-4 mb-md-0'>Dove si può studiare</h2>
                                </div>
                                <div className='col-12 col-md-6 col-lg-4 col-xl-3 d-flex align-items-center justify-content-end'>
                                    <select
                                        id="change-region"
                                        defaultValue={20}
                                        name="regioni-italia"
                                        title='Scegli una regione'
                                        onChange={(e) => setSelectedRegion(Number(e.target.value))}
                                        className="border-primary border rounded-3 form-select fw-semibold w-100"
                                    >
                                        <option value="0">Piemonte</option>
                                        <option value="1">Valle d'Aosta</option>
                                        <option value="2">Lombardia</option>
                                        <option value="3">Trentino-Alto Adige</option>
                                        <option value="4">Veneto</option>
                                        <option value="5">Friuli-Venezia Giulia</option>
                                        <option value="6">Liguria</option>
                                        <option value="7">Emilia-Romagna</option>
                                        <option value="8">Toscana</option>
                                        <option value="9">Umbria</option>
                                        <option value="10">Marche</option>
                                        <option value="11">Lazio</option>
                                        <option value="12">Abruzzo</option>
                                        <option value="13">Molise</option>
                                        <option value="14">Campania</option>
                                        <option value="15">Puglia</option>
                                        <option value="16">Basilicata</option>
                                        <option value="17">Calabria</option>
                                        <option value="18">Sicilia</option>
                                        <option value="19">Sardegna</option>
                                        <option value="20">Italia</option>
                                    </select>
                                </div>
                            </div>
                            {selectedRegion === 20 && (
                                <div className='row'>
                                    <div className="col-12 my-4">
                                        <p className="fs-5 alert alert-info fw-semibold mb-4">
                                            Selezione una regione per visualizzare le opportunità formative specifiche
                                        </p>
                                    </div>
                                </div>
                            )}
                            {filteredPlaces.length > 0 ? (
                                <div className='row my-5 places-swiper-wrapper'>
                                    <div className={`places-swiper-nav d-flex align-items-center pt-5 position-absolute w-100 top-50 px-5 ${filteredPlaces.length > 3 ? "" : "d-none"}`}>
                                        <span className='swiper-button-prev' ref={prevButtonRef}></span>
                                        <span className='swiper-button-next' ref={nextButtonRef}></span>
                                    </div>
                                    <Swiper
                                        modules={[Navigation, Scrollbar, A11y]}
                                        spaceBetween={20}
                                        slidesPerView={3}
                                        navigation={
                                            filteredPlaces.length > 3 ? {
                                                nextEl: document.querySelector('.places-swiper-nav .swiper-button-next'),
                                                prevEl: document.querySelector('.places-swiper-nav .swiper-button-prev'),
                                            } : false
                                        }
                                        className='pb-5'
                                        scrollbar={{ draggable: true }}
                                        breakpoints={{
                                            0: {
                                                slidesPerView: 1.4,
                                                spaceBetween: 20
                                            },
                                            575: {
                                                slidesPerView: 2
                                            },
                                            992: {
                                                slidesPerView: 3,
                                                spaceBetween: 50
                                            }
                                        }}
                                        id='places-swiper'
                                        ref={placesSwiperRef}
                                    >
                                        {filteredPlaces.map((place, index) => (
                                            <SwiperSlide key={index} className={`h-100 ps-3 ps-lg-0 ${index === filteredPlaces.length - 1 ? "pe-3" : ""}`}>
                                                <div className='card border-0 bg-100 h-100 rounded-3' key={index}>
                                                    <div className='card-body d-flex flex-column justify-content-between'>
                                                        <div className='main'>
                                                            <h3 className='h5 fw-bold mb-2'>{place.courseName}</h3>
                                                            {place.level && (<p className='mb-4'>{place.level}</p>)}
                                                        </div>
                                                        <div className='info'>
                                                            {isCollegeDegree && (<p className='mb-2'>Ente: <span className='fw-semibold'>{place.operativeName}</span></p>)}
                                                            <p className='mb-2'>Sede: <span className='fw-semibold'>{place.location}</span></p>
                                                        </div>
                                                        <a
                                                            href={place.websiteUrl}
                                                            target='_blank'
                                                            rel='noreferrer'
                                                            className='text-decoration-none text-primary fw-semibold text-uppercase pt-3'>
                                                            Visita il sito
                                                        </a>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            ) : (
                                <div className='row'>
                                    <div className="col-12 my-4">
                                        <p className="fs-5 alert alert-info fw-semibold mb-4">
                                            Non sono stati attivati percorsi di studio di questa tipologia nella regione scelta
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='container-fluid mb-5'>
                        {isIts ? (
                            <div className='row justify-content-center bg-100'>
                                <div className='col-12 col-md-8 col-lg-6 py-5 px-3 px-lg-5 d-flex flex-column align-items-center justify-content-between'>
                                    <h3 className='h4 text-center mb-5 text-black fw-normal lh-base pt-5'>
                                        Sul sito ufficiale degli <span className='fw-bold'>Istituti Tecnologici Superiori (ITS Academy)</span> puoi trovare maggiori informazioni ed aggiornamenti
                                    </h3>
                                    <a href='https://sistemaits.it/' target='_blank' rel='noreferrer' className='btn btn-primary rounded-0 fw-700 fs-5 text-uppercase px-4 py-3 lh-sm mb-5'>Visita il sito</a>
                                </div>
                            </div>
                        ) : (
                            <div className='row'>
                                <div className='col-12 col-lg-6 py-5 px-3 px-lg-5 bg-primary d-flex flex-column align-items-center justify-content-between'>
                                    <h3 className='h4 text-center mb-5 text-white fw-normal lh-base pt-5'>
                                        Su <span className='fw-bold'>Almalaurea</span> puoi trovare indicazioni sulla
                                        condizione occupazionale e sulla situazione post-laurea
                                    </h3>
                                    <a href='https://www.almalaurea.it/' target='_blank' rel='noreferrer' className='btn btn-white rounded-0 fw-700 fs-5 text-uppercase px-4 py-3 lh-sm mb-5'>Visita il sito</a>
                                </div>
                                <div className='col-12 col-lg-6 py-5 px-3 px-lg-5 bg-100 d-flex flex-column align-items-center justify-content-between'>
                                    <h3 className='h4 text-center mb-5 text-black fw-normal lh-base pt-5'>
                                        Su <span className='fw-bold'>Universitaly</span> puoi trovare ulteriori
                                        informazioni sui corsi di laurea in tutta Italia o nella tua regione
                                    </h3>
                                    <a href='https://www.universitaly.it/cerca-corsi' target='_blank' rel='noreferrer' className='btn btn-primary rounded-0 fw-700 fs-5 text-uppercase px-4 py-3 lh-sm mb-5'>Visita il sito</a>
                                </div>
                            </div>
                        )}
                    </div>
                </>
            )}
            {(isTechnicalInstitute || isHighSchool) && (
                <GeoSchoolSearch field={selectedField} />
            )}
            {(isTechnicalInstitute || isHighSchool) && (
                <div className='bg-100'>
                    <div className='container py-5'>
                        <div className='row'>
                            <div className='col-12 col-lg-9'>
                                <h2 className='h3 fw-bold mb-4 text-black'>Vuoi avere dati sulle prestazioni degli studenti di una scuola che ti interessa?</h2>
                                <p className='fs-5 lh-base text-black pe-0 pe-lg-4 pe-xl-0'>
                                    Su <span className='fw-bold'>Eduscopio</span> puoi trovare informazioni legate al successo dopo il diploma, sia sul lavoro che in università, degli studenti degli istituti e licei italiani.
                                </p>
                            </div>
                            <div className='col-12 col-lg-3 d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-lg-end mt-4 mt-lg-0'>
                                <a href='https://www.eduscopio.it/'
                                    target='_blank' rel='noreferrer' className='btn btn-primary rounded-0 fw-700 fs-5 text-start text-uppercase px-4 py-3 lh-sm'>
                                    Visita il sito
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isProTraining && (
                <RegionStudy />
            )}
            <OrientaCta />
        </>
    );
};

export default DettaglioPercorso;
