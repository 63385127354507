import React, { useEffect, useState, useRef } from 'react';
import GeoHighSchool from '../../data/geo-high-schools.json';
import Cities from '../../data/municipalities.json';
import { Navigation, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';

const calculateDistance = (coord1, coord2) => {
    const toRad = (value) => (value * Math.PI) / 180;
    const R = 6371; // raggio della terra in km

    const dLat = toRad(coord2[1] - coord1[1]);
    const dLon = toRad(coord2[0] - coord1[0]);

    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRad(coord1[1])) * Math.cos(toRad(coord2[1])) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // distanza in km
};

const GeoSchoolSearch = ({ field }) => {
    const municipalities = Cities;
    const fieldCode = field.code;
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [municipalityMatches, setMunicipalityMatches] = useState([]);
    const [isQueried, setIsQueried] = useState(false);
    const placesSwiperRef = useRef(null);
    const nextButtonRef = useRef(null);
    const prevButtonRef = useRef(null);

    useEffect(() => {
        if (query) {
            const matches = municipalities.filter(municipality =>
                municipality.name.toLowerCase().includes(query.toLowerCase())
            );
            setMunicipalityMatches(matches);
        } else {
            setMunicipalityMatches([]);
        }
    }, [query]);

    const handleSelectMunicipality = (municipality) => {
        setQuery("");
        setMunicipalityMatches([]);
        setIsQueried(true);

        // filtro le scuole per il campo di studio selezionato
        const fieldFiltered = GeoHighSchool.filter(school => school.code === fieldCode);

        // filtro le scuole per il comune selezionato
        const distanceFilteredSchools = fieldFiltered.filter(school => {
            const distance = calculateDistance(municipality.geo.coordinates, school.geo.coordinates);
            return distance <= 30;
        }).sort((a, b) => {
            // ordino le scuole per distanza crescente rispetto al comune cercato
            const distanceA = calculateDistance(municipality.geo.coordinates, a.geo.coordinates);
            const distanceB = calculateDistance(municipality.geo.coordinates, b.geo.coordinates);
            return distanceA - distanceB;
        });

        // rimuovo le scuole duplicate
        const uniqueSchools = [];
        distanceFilteredSchools.forEach(school => {
            if (!uniqueSchools.some(uniqueSchool => (
                uniqueSchool.schoolType === school.schoolType &&
                uniqueSchool.schoolName === school.schoolName &&
                uniqueSchool.schoolAddress === school.schoolAddress &&
                uniqueSchool.municipalityName === school.municipalityName &&
                uniqueSchool.region === school.region &&
                uniqueSchool.province === school.province
            ))) {
                uniqueSchools.push(school);
            }
        });

        setResults(uniqueSchools);
    };

    useEffect(() => {
        // Solo se tutti i riferimenti sono pronti
        if (placesSwiperRef.current && nextButtonRef.current && prevButtonRef.current) {
            setTimeout(() => {
                const swiperInstance = placesSwiperRef.current?.swiper;
                if (nextButtonRef.current && prevButtonRef.current && swiperInstance) {
                    // Assicurati che i riferimenti siano validi
                    const swiperInstance = placesSwiperRef.current?.swiper;
                    if (swiperInstance) {
                        swiperInstance.params.navigation.nextEl = nextButtonRef.current;
                        swiperInstance.params.navigation.prevEl = prevButtonRef.current;
                        swiperInstance.navigation.init();
                        swiperInstance.navigation.update();
                    }
                }

            }, 1000);
        }
    }, [placesSwiperRef.current, nextButtonRef.current, prevButtonRef.current, results]);

    return (
        <div className='position-relative'>
            <div className='container mt-5 py-5'>
                <div className='row mb-4'>
                    <div className='col-12 col-md-6 col-lg-8 col-xl-9'>
                        <h2 className='h3 fw-bold mb-4 mb-md-0'>Dove si può studiare</h2>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4 col-xl-3 d-flex align-items-center justify-content-end'>
                        <div className='form-group mb-0 w-100 position-relative'>
                            <input
                                type='text'
                                className='form-control form-control-lg rounded-pill border px-4 border-primary'
                                placeholder='Il mio comune di residenza'
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                            />
                            {municipalityMatches.length > 0 && (
                                <ul className='list-group position-absolute w-100 mt-1 z-2 input-select-results-wrapper'>
                                    {municipalityMatches.map(municipality => (
                                        <li
                                            key={municipality._id.$oid}
                                            className='list-group-item list-group-item-action cursor-pointer'
                                            onClick={() => handleSelectMunicipality(municipality)}
                                        >
                                            {municipality.name}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                </div>
                {results.length > 0 ? (
                    <div className='row my-5 places-swiper-wrapper'>
                        <div className={`places-swiper-nav d-flex align-items-center pt-5 position-absolute w-100 top-50 px-5 ${results.length > 3 ? "" : "d-none"}`}>
                            <span className='swiper-button-prev' ref={prevButtonRef}></span>
                            <span className='swiper-button-next' ref={nextButtonRef}></span>
                        </div>
                        <Swiper
                            modules={[Navigation, Scrollbar, A11y]}
                            spaceBetween={20}
                            slidesPerView={3}
                            navigation={{
                                nextEl: nextButtonRef.current,
                                prevEl: prevButtonRef.current,
                            }}
                            className='pb-5'
                            scrollbar={{ draggable: true }}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1.4,
                                    spaceBetween: 20
                                },
                                575: {
                                    slidesPerView: 2
                                },
                                992: {
                                    slidesPerView: 3,
                                    spaceBetween: 50
                                }
                            }}
                            ref={placesSwiperRef}
                            onSwiper={(swiper) => {
                                if (nextButtonRef.current && prevButtonRef.current) {
                                    swiper.params.navigation.nextEl = nextButtonRef.current;
                                    swiper.params.navigation.prevEl = prevButtonRef.current;
                                    swiper.navigation.init();
                                    swiper.navigation.update();
                                }
                            }}
                        >
                            {results.map((currentSchool, index) => (
                                <SwiperSlide key={index} className={`h-100 ps-3 ps-lg-0 ${index === results.length - 1 ? "pe-3" : ""}`}>
                                    <div className='card border-0 bg-100 h-100 rounded-3' key={index}>
                                        <div className='card-body d-flex flex-column justify-content-between'>
                                            <div className='main'>
                                                <h3 className='h5 fw-bold mb-2'>{currentSchool.schoolName}</h3>
                                                {currentSchool.schoolType && (<p className='mb-4'>{currentSchool.schoolType}</p>)}
                                            </div>
                                            <div className='info'>
                                                {currentSchool.schoolAddress && (
                                                    <p className='mb-2'>Indirizzo: <span className='fw-semibold'>{currentSchool.schoolAddress}</span></p>
                                                )}
                                                <p className='mb-2'>Comune: <span className='fw-semibold'>
                                                    {currentSchool.municipalityName === currentSchool.province
                                                        ? currentSchool.municipalityName
                                                        : `${currentSchool.municipalityName} (${currentSchool.province})`}
                                                </span></p>
                                            </div>
                                            <a
                                                href={`https://${currentSchool.schoolWebsite}`}
                                                target='_blank'
                                                rel='noreferrer'
                                                className='text-decoration-none text-primary fw-semibold text-uppercase pt-3'>
                                                Visita il sito
                                            </a>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                ) : (
                    <>
                        {isQueried && (
                            <div className='row no-results-el'>
                                <div className="col-12 mt-4">
                                    <p className="fs-5 alert alert-warning fw-semibold">
                                        Non ci sono scuole con l'indirizzo desiderato nel raggio di 30km di distanza dal tuo comune di residenza.
                                    </p>
                                </div>
                            </div>
                        )}
                        <div className='row'>
                            <div className="col-12 my-4">
                                <p className="fs-5 alert alert-info fw-semibold mb-4">
                                    Inserisci il tuo comune di residenza e ti mostreremo le scuole in un raggio di 30km di distanza
                                </p>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default GeoSchoolSearch;
