import React, { useEffect, useState } from 'react';
import SkillsJsonData from '../../data/skills.json';
import * as Icon from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import OrientaCta from '../components/OrientaCta.jsx';
import Jobs from '../../data/jobs.json';
import JobsInSkill from '../../data/job-in-skill.json';

const DettaglioSkill = ({ selectedSkill }) => {
    const [selectedOrder, setSelectedOrder] = useState(0);
    const [retrivedJobs, setRetrivedJobs] = useState([]);
    const [jobsWithTrend, setJobsWithTrend] = useState([]);
    const [sortedJobsWithTrend, setSortedJobsWithTrend] = useState([]);
    const [mySkill, setMySkill] = useState(selectedSkill);

    // elementi principali (pulsanti blu) 
    // dati ricavati da file di appoggio, per gli altri viene usato algoritmo
    const firstElementsIds = ["cpt_10", "cpt_04", "cpt_09", "cpt_03", "cpt_12"];

    useEffect(() => {
        setMySkill(selectedSkill);
    }, [selectedSkill]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const accordionTrg = document.querySelector('a.custom-info-accordion-trg');
        const accordion = document.querySelector('div.custom-info-accordion');
        if (accordionTrg && accordion) {
            const handleClick = (e) => {
                e.preventDefault();
                accordion.classList.toggle('open');
                window.scrollTo(0, 0);
            };
            accordionTrg.addEventListener('click', handleClick);
            return () => {
                accordionTrg.removeEventListener('click', handleClick);
            };
        }
    }, []);

    const unitaProfessionaliIstat = [
        { num: 1, name: "LEGISLATORI, IMPRENDITORI E ALTA DIRIGENZA", bg: "bg-danger-subtle" },
        { num: 2, name: "PROFESSIONI INTELLETTUALI, SCIENTIFICHE E DI ELEVATA SPECIALIZZAZIONE", bg: "bg-info-subtle" },
        { num: 3, name: "PROFESSIONI TECNICHE", bg: "bg-warning-subtle" },
        { num: 4, name: "PROFESSIONI ESECUTIVE NEL LAVORO D'UFFICIO", bg: "bg-success-subtle" },
        { num: 5, name: "PROFESSIONI QUALIFICATE NELLE ATTIVITÀ COMMERCIALI E NEI SERVIZI", bg: "bg-primary-subtle" },
        { num: 6, name: "ARTIGIANI, OPERAI SPECIALIZZATI E AGRICOLTORI", bg: "bg-rose-subtle" },
        { num: 7, name: "CONDUTTORI DI IMPIANTI, OPERAI DI MACCHINARI FISSI E MOBILI E CONDUCENTI DI VEICOLI", bg: "bg-purple-subtle" },
        { num: 8, name: "PROFESSIONI NON QUALIFICATE", bg: "bg-100" },
    ];



    /* 
     *  HIGH SKILL CHECK
     */

    var Skills;
    (function (Skills) {
        Skills[Skills["cpt_01"] = 0] = "cpt_01";
        Skills[Skills["cpt_02"] = 1] = "cpt_02";
        Skills[Skills["cpt_03"] = 2] = "cpt_03";
        Skills[Skills["cpt_04"] = 3] = "cpt_04";
        Skills[Skills["cpt_05"] = 4] = "cpt_05";
        Skills[Skills["cpt_06"] = 5] = "cpt_06";
        Skills[Skills["cpt_07"] = 6] = "cpt_07";
        Skills[Skills["cpt_08"] = 7] = "cpt_08";
        Skills[Skills["cpt_09"] = 8] = "cpt_09";
        Skills[Skills["cpt_10"] = 9] = "cpt_10";
        Skills[Skills["cpt_11"] = 10] = "cpt_11";
        Skills[Skills["cpt_12"] = 11] = "cpt_12";
    })(Skills || (Skills = {}));

    // solo per settori non presenti in firstElementsIds
    //
    function checkSkillDifficulty(job, selectedRegion) {
        const sectors = job.sectors;

        const skillsData = () => {
            const skillsData2 = [];
            let totalEntries = 0;

            sectors.forEach((sector) => {
                totalEntries += Number(sector.entries[selectedRegion]);

                sector.skills.forEach((currentSkill, index) => {
                    if (skillsData2[index]) {
                        skillsData2[index].entries += Number(currentSkill);
                    } else {
                        const skill = SkillsJsonData.find((s) => s.id === Skills[index]);
                        skillsData2[index] = {
                            name: skill ? skill.label : undefined,
                            hidden: skill ? skill.hidden : undefined,
                            entries: Number(currentSkill),
                        };
                    }
                });
            });

            // Per Competenze interculturali, nessuna professione ha un valore di entries/totalEntries > 0.5
            const toReturn = skillsData2.map((value) => (
                {
                    name: value.name,
                    hidden: value.hidden,
                    difficulty: value.entries / totalEntries > 0.8
                        ? 2
                        : value.entries / totalEntries > 0.5
                            ? 1
                            : 0,
                }));

            return toReturn.filter((value) => value.hidden !== true);
        };

        const firstFilter = skillsData().filter((skill) => skill.difficulty === 2);
        return firstFilter;
    }



    // solo per settori non presenti in firstElementsIds
    //
    useEffect(() => {
        if (firstElementsIds.includes(mySkill.id)) return;
        const retrived = [];
        Jobs.forEach((job) => {
            const foundSkills = checkSkillDifficulty(job, 20);
            if (mySkill && mySkill.name) {
                const filteredBySkill = foundSkills.filter((skill) => skill.name === mySkill.label);
                if (filteredBySkill.length > 0) {
                    retrived.push(job);
                }
            }
        });
        const limited = retrived.slice(0, 200); //limit results num to 200
        setRetrivedJobs(limited);
    }, [mySkill]);




    /* 
     *  JOB TREND DATA
     */
    function getTrendData(region, sectorId, job) {
        const jobSectors = job ? job.sectors : Jobs;
        const sectors = jobSectors.filter((s) => sectorId ? s.sectorId === sectorId : true);
        const trendReferenceYear = 2024;
        return {
            past: {
                year: trendReferenceYear - 3,
                value: Math.round(sectors.reduce((previousValue, currentValue) => {
                    if (!!currentValue.pastEntries[region]) {
                        previousValue += Number(currentValue.pastEntries[region]);
                    }
                    return previousValue;
                }, 0) / 10) * 10
            },
            present: {
                year: trendReferenceYear,
                value: Math.round(sectors.reduce((previousValue, currentValue) => {
                    if (!!currentValue.entries[region]) {
                        previousValue += Number(currentValue.entries[region]);
                    }
                    return previousValue;
                }, 0) / 10) * 10
            }
        };
    }

    function getDifficultyPercentage(job) {
        let region = 20;
        let sectorId = ""; //lasciare vuoto altrimenti NaN
        let diffTot = 0;
        let entTot = 0;
        job.sectors.filter((s) => sectorId ? s.sectorId === sectorId : true).forEach((s) => {
            diffTot += Number(s.findingDifficulty[region]);
            entTot += Number(s.entries[region]);
        });
        if (entTot >= 0) {
            return Math.floor(diffTot / entTot * 100);
        }
        return -1;
    }

    function getYoungJob(job) {
        let region = 20;
        let jobSectors = job.sectors;
        const tot = jobSectors.reduce((previousValue, currentValue, currentIndex, array) => {
            if (!!currentValue.entries[region]) {
                previousValue += Number(currentValue.entries[region]);
            }
            return previousValue;
        }, 0);
        const young = jobSectors.reduce((previousValue, currentValue, currentIndex, array) => {
            return previousValue + Number(currentValue.youngEnt[region]);
        }, 0);
        return Math.round(young / tot * 100);
    }

    useEffect(() => {
        let jobsWithTrend = [];
        // INSERITO CON I FILE DI APPOGGIO "job-in-sector.json"
        if (firstElementsIds.includes(mySkill.id)) {
            const jobsWithSelectedSector = JobsInSkill.filter((jobsInSkillEl) => jobsInSkillEl.skillId === selectedSkill.id);
            const onlyShowJobs = jobsWithSelectedSector.map((job) => job.show === true ? job : null).filter((el) => el !== null);
            const fullJobDetail = Jobs.filter((job) => onlyShowJobs.find((jobInSector) => jobInSector.fourDigitCode === job.fourDigitCode));

            jobsWithTrend = fullJobDetail.map((job) => {  // fullJobDetail ERA retrivedJobs
                const trendData = getTrendData(20, "", job);
                const percent = ((trendData.present.value - trendData.past.value) / trendData.past.value) * 100;
                const max100ifInfinity = percent === Infinity ? 100 : percent;
                const endValue = trendData.present.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                const endDate = trendData.present.year;
                const difficultyPercentage = getDifficultyPercentage(job);
                const youngJob = getYoungJob(job);
                return {
                    ...job,
                    trendPercent: Math.round(max100ifInfinity * 100) / 100,
                    trendEndValue: endValue,
                    trendEndYear: endDate,
                    youngJobPercentage: youngJob,
                    difficultyPercentage: difficultyPercentage,
                    difficulty: Math.round((difficultyPercentage === Infinity ? 100 : difficultyPercentage) * 100) / 100
                };
            });
        } else {
            jobsWithTrend = retrivedJobs.map((job) => {
                const trendData = getTrendData(20, "", job);
                const percent = ((trendData.present.value - trendData.past.value) / trendData.past.value) * 100;
                const max100ifInfinity = percent === Infinity ? 100 : percent;
                const endValue = trendData.present.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                const endDate = trendData.present.year;
                const difficultyPercentage = getDifficultyPercentage(job);
                const youngJob = getYoungJob(job);
                return {
                    ...job,
                    trendPercent: Math.round(max100ifInfinity * 100) / 100,
                    trendEndValue: endValue,
                    trendEndYear: endDate,
                    youngJobPercentage: youngJob,
                    difficultyPercentage: difficultyPercentage,
                    difficulty: Math.round((difficultyPercentage === Infinity ? 100 : difficultyPercentage) * 100) / 100
                };
            });
        }
        // END --- INSERITO CON I FILE DI APPOGGIO "job-in-sector.json"

        /*  
            Limite inserito, ora il sistema prende le professioni con più di 245 assunzioni per tutte tranne Tecnologie 4.0 e ne mostra massimo 21 blocchi (in modo da mantenere un layout di griglia di 3 card per riga nel caso massimo).
            Se una professione ha 500 assunzione, ma prima ci sono altre 21 con piu assunzioni, questa non viene mostrata
            Se una professione ha 190 assunzioni, questa non viene mostrata 
         */
        // AGGIORNATO CON I FILE DI APPOGGIO "job-in-sector.json"
        const minAssunzioni = jobsWithTrend.filter((job) => parseInt(job.trendEndValue.replace(/\./g, ''), 10) >= (mySkill.slug === 'applicare-tecnologie-4.0' ? 30 : 245));
        const orderedByAssunzioni = minAssunzioni.sort((a, b) => parseInt(b.trendEndValue.replace(/\./g, ''), 10) - parseInt(a.trendEndValue.replace(/\./g, ''), 10));
        const limitedTo21 = orderedByAssunzioni.slice(0, 21);
        if (firstElementsIds.includes(mySkill.id)) {
            setJobsWithTrend(orderedByAssunzioni);
        } else {
            setJobsWithTrend(limitedTo21);
        }
    }, [mySkill, retrivedJobs, JobsInSkill]);

    useEffect(() => {
        const parseTrendEndValue = (value) => parseInt(value.replace(/\./g, ''), 10);
        const sortedJobs = [...jobsWithTrend].sort((a, b) => {
            switch (selectedOrder) {
                case 0:
                    return parseTrendEndValue(b.trendEndValue) - parseTrendEndValue(a.trendEndValue);
                case 1:
                    return b.youngJobPercentage - a.youngJobPercentage;
                case 2:
                    return b.difficultyPercentage - a.difficultyPercentage;
                default:
                    return 0;
            }
        });
        setSortedJobsWithTrend(sortedJobs);
    }, [selectedOrder, jobsWithTrend]);

    return (
        <>
            <div className="container py-3">
                <div className="row">
                    <a href='/professioni' className="col-12 d-flex align-items-center">
                        <Icon.ArrowLeftShort size={30} className='text-primary mw-icon me-2 ms-n1' />
                        <span className='text-uppercase fs-6 fw-bold'>Indietro</span>
                    </a>
                </div>
            </div>
            <div className='bg-primary page-title-row position-sticky' style={{ top: "4.5rem", zIndex: 4, boxShadow: "-4px 14px 20px 0px #00000026" }}>
                <div className="container py-4">
                    <div className="row">
                        <div className="col-10">
                            <h1 className="mb-0 text-white">{selectedSkill.name}</h1>
                        </div>
                        <div className='col-2 d-flex align-items-center justify-content-end'>
                            <a href='#' className='border border-white custom-info-accordion-trg rouded-50 rounded-circle text-decoration-none text-white' title="Informazioni sui percorsi">
                                i
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container custom-info-accordion'>
                <div className='row'>
                    <div className='col-12'>
                        <p className='alert alert-info fw-normal mt-5'>
                            All'interno di questa tabella puoi trovare le professioni per le quali le aziende richiedono ai candidati la competenza, sulla base dei dati delle imprese del sistema camerale italiano, raccolti dal Sistema Informativo Excelsior.
                            <br /><br />
                            <span className="fw-bold">Quota giovani under 30</span><br />
                            Variazione percentuale di attivazione di contratti nell’arco degli ultimi quattro anni, cioè dal 2020 al 2024.
                            <br /><br />
                            <span className="fw-bold">Difficoltà di reperimento</span><br />
                            Il punto di vista dei datori di lavoro sulla difficoltà di assumere persone per ricoprire quella specifica professione nell'ultimo anno (2022). Un'alta difficoltà di reperimento calcolata in rapporto al valore medio della difficoltà di reperimento di tutte le professioni) è spia del fatto che l'offerta di lavoro per quella professione non è sufficiente a coprire la domanda del mercato.
                            <br /><br />
                            <span className="fw-bold">Legenda unità professionali ISTAT</span><br />
                            <div className='pt-3 pb-1 border border-light border-end-0 border-start-0 border-bottom-0 mt-2'>
                                {unitaProfessionaliIstat.map((u, index) => (
                                    <>
                                        <span key={index} className={`d-none d-md-inline-block badge rounded-pill text-black mb-3 fs-7 px-3 py-2 ${u.bg}`}>{u.name}</span>
                                        <span className={`d-flex align-items-start d-md-none`}>
                                            <span className={`badge rounded-pill text-black fs-7 px-3 py-2 me-n3 ${u.bg}`}>&nbsp;</span>
                                            {u.name}
                                            <br />
                                        </span>
                                        <br />
                                    </>
                                ))}
                            </div>
                        </p>
                    </div>
                </div>
            </div>
            <div className="container pt-5">
                <div className='row'>
                    <div className='col-12'>
                        <p className='fs-4 lh-base'>
                            {selectedSkill.description}
                            <br /><br />
                            Esplora le principali professioni per le quali le imprese dell'industria e dei servizi richiedono questo percorso di studi, secondo i dati raccolti dal Sistema Informativo
                            Excelsior. Questa lista non è esaustiva, puoi trovare maggiori approfondimenti alla <a href='https://excelsior.unioncamere.net/' target='_blank' rel='noreferrer'>sezione "Pubblicazioni" del portale Excelsior</a>.
                            <br /><br />
                            Le seguenti professioni richiedono un grado elevato della competenza <span className='fw-bold text-lowercase'>{selectedSkill.label}.</span>
                        </p>
                    </div>
                </div>
                <div className='row mt-5 pt-lg-3'>
                    <div className='col-12 d-flex flex-column flex-lg-row align-items-start align-items-lg-center justify-content-start'>
                        <p className='h5 text-uppercase mb-4 me-lg-4 mb-lg-0'>Ordina per</p>
                        <select
                            id="change-order"
                            defaultValue={0}
                            name="order-by"
                            title='Ordina lista dei lavori per:'
                            onChange={(e) => setSelectedOrder(Number(e.target.value))}
                            className="border-primary border rounded-3 form-select fw-semibold"
                            style={{ width: "fit-content", maxWidth: "95%" }}
                        >
                            <option value="0">Assunzioni</option>
                            {/* <option value="1">Assunzioni - CRESCENTE</option> */}
                            <option value="1">Quota giovani under 30</option>
                            {/* <option value="3">Quota giovani under 30 - CRESCENTE</option> */}
                            <option value="2">Difficoltà di reperimento</option>
                            {/* <option value="5">Difficoltà di reperimento - CRESCENTE</option> */}
                        </select>
                    </div>
                </div>
            </div >
            {sortedJobsWithTrend.length > 0 ? (
                <div className="container pb-5 mb-5 mt-lg-5">
                    <div className={`row justify-content-start d-flex align-items-stretch pt-5 pt-lg-0`}>
                        {sortedJobsWithTrend.map((job, index) => (
                            <div className='col-12 col-lg-4 mb-5 mb-lg-4 mt-lg-4' key={index}>
                                <div className={`card border-0 h-100 rounded-3 ${unitaProfessionaliIstat.find((u) => u.num === parseInt(job.fourDigitCode.slice(0, 1), 10))?.bg}`} title={job.jobName}>
                                    <div className='card-body d-flex flex-column justify-content-between'>
                                        <div className='main'>
                                            <h4 className='fs-7 fw-semibold mb-3 lh-sm'>{
                                                unitaProfessionaliIstat.find((u) => u.num === parseInt(job.fourDigitCode.slice(0, 1), 10))?.name
                                            }</h4>
                                            <h3 className='h5 fw-bold mb-4 ellipsis'>{job.jobName}</h3>
                                        </div>
                                        <div>
                                            <div className='info'>
                                                <p className='mb-4'>
                                                    {!isNaN(job.difficulty) && (
                                                        <>
                                                            Difficoltà di reperimento: <span className='fw-bold text-uppercase'>{job.difficulty}%</span>
                                                            <br />
                                                        </>
                                                    )}
                                                    {/* {!isNaN(job.trendPercent) && (
                                                        <>
                                                            Quota giovani under 30: <span className='fw-bold'>{`${job.trendPercent > 0 ? '+' : ''}`}{job.trendPercent}%</span>
                                                            <br />
                                                        </>
                                                    )} */}
                                                    {!isNaN(job.youngJobPercentage) && (
                                                        <>
                                                            {/* RIMOSSO IL + DAVANTI ALLA PERCENTUALE */}
                                                            {/* Quota giovani under 30: <span className='fw-bold'>{`${job.youngJobPercentage > 0 ? '+' : ''}`}{job.youngJobPercentage}%</span> */}
                                                            Quota giovani under 30: <span className='fw-bold'>{job.youngJobPercentage}%</span>
                                                            <br />
                                                        </>
                                                    )}
                                                    {`Assunzioni nell'anno ${job.trendEndYear}:`} <span className='fw-bold'>{`${job.trendEndValue}`}</span>
                                                </p>
                                            </div>
                                            <Link
                                                key={index}
                                                to={`/professioni/${job.slug}`}
                                                className='text-uppercase text-decoration-none text-primary fw-semibold'>
                                                Visualizza professione
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div className="container py-5">
                    <div className="row">
                        <div className="col-12">
                            <p className="fs-5 alert alert-info fw-semibold mb-0">
                                Nessun risultato trovato
                            </p>
                        </div>
                    </div>
                </div>
            )}
            < div className='container py-5' >
                <div className='row'>
                    <div className='col-12'>
                        <h2 className='h4 text-center text-uppercase'>Scopri tutte le altre professioni</h2>
                    </div>
                    <div className='col-12 pb-5 pt-4 d-flex align-items-center justify-content-center'>
                        <Link
                            to='/professioni'
                            className='text-decoration-none fw-semibold text-uppercase btn btn-primary rounded-0'
                        >
                            cerca le professioni
                        </Link>
                    </div>
                </div>
            </div >
            <OrientaCta />
        </>
    );
};

export default DettaglioSkill;
