import React, { useEffect, useState } from 'react';
import SkillsJsonData from '../../data/skills.json';
import FieldsData from '../../data/fields.json';
import FieldInJobData from '../../data/job-in-field.json';
import { Link } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import TrendBarGraph from '../components/TrendBarGraph.jsx';
import ProgressLine from '../components/ProgressLine.jsx';
import PieProgressGraph from '../components/PieProgressGraph.jsx';
import BigBarGraph from '../components/BigBarGraph.jsx';
import HorizontalBarGraph from '../components/HorizontalBarGraph.jsx';
import OrientaCta from '../components/OrientaCta.jsx';

const DettaglioProfessione = ({ selectedJob }) => {
    const [isMobile, setIsMobile] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [selectedRegion, setSelectedRegion] = useState(0);

    const jobSectors = selectedJob.sectors;
    const skillsJsonData = SkillsJsonData;
    const fieldsData = FieldsData;
    const fieldInJobData = FieldInJobData;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setIsMobile(window.innerWidth < 574 || (window.innerWidth > 767 && window.innerWidth < 1200));
        window.addEventListener('resize', () => {
            setIsMobile(window.innerWidth < 574 || (window.innerWidth > 767 && window.innerWidth < 1200));
        });
    }, []);

    useEffect(() => {
        setWindowWidth(window.innerWidth);
        window.addEventListener('resize', () => {
            setWindowWidth(window.innerWidth);
        });
    }, []);

    useEffect(() => {
        var select = document.getElementById('change-region');
        setSelectedRegion(Number(select.value));
    }, []);


    /*
     * OFFERTE DI LAVORO - Progress Pie
     */
    const [youngJob, setJoungJob] = useState(0);

    function getYoungPercentage(region) {
        const tot = jobSectors.reduce((previousValue, currentValue, currentIndex, array) => {
            if (!!currentValue.entries[region]) {
                previousValue += Number(currentValue.entries[region]);
            }
            return previousValue;
        }, 0);
        const young = jobSectors.reduce((previousValue, currentValue, currentIndex, array) => {
            return previousValue + Number(currentValue.youngEnt[region]);
        }, 0);
        return Math.round(young / tot * 100);
    }

    useEffect(() => {
        setJoungJob(getYoungPercentage(selectedRegion));
    }, [selectedRegion]);



    /*
     * DIFFICOLTÀ AZIENDE - Progress bar
     */
    const [findingDifficulty, setFindingDifficulty] = useState(0);

    function getDifficultyPercentage(region, sectorId) {
        let diffTot = 0;
        let entTot = 0;
        jobSectors.filter((s) => sectorId ? s.sectorId === sectorId : true).forEach((s) => {
            diffTot += Number(s.findingDifficulty[region]);
            entTot += Number(s.entries[region]);
        });
        if (entTot >= 0) {
            return Math.floor(diffTot / entTot * 100);
        }
        return -1;
    }

    useEffect(() => {
        setFindingDifficulty(getDifficultyPercentage(selectedRegion, ""));
    }, [selectedRegion]);




    /*
     * TREND OCCUPAZIONALE - Grafico a barre
     */
    const [trendStartYear, setTrendStartYear] = useState(2022);
    const [trendEndYear, setTrendEndYear] = useState(2024);
    const [trendPercent, setTrendPercent] = useState(0);
    const [trendStartValue, setTrendStartValue] = useState(0);
    const [trendEndValue, setTrendEndValue] = useState(0);

    function getTrendData(region, sectorId) {
        const sectors = jobSectors.filter((s) => sectorId ? s.sectorId === sectorId : true); // sectors del job selezionato
        const trendReferenceYear = trendEndYear; // anno attuale
        return {
            past: {
                year: trendReferenceYear - 2,
                value: Math.round(sectors.reduce((previousValue, currentValue) => {
                    if (!!currentValue.pastEntries[region]) {
                        previousValue += Number(currentValue.pastEntries[region]);
                    }
                    return previousValue; // calcola prendendo i dati dalla voce pastEntries dei sector che hanno isExposed=true del job selezionato
                }, 0) / 10) * 10
            },
            present: {
                year: trendReferenceYear,
                value: Math.round(sectors.reduce((previousValue, currentValue) => {
                    if (!!currentValue.entries[region]) {
                        previousValue += Number(currentValue.entries[region]);
                    }
                    return previousValue;
                }, 0) / 10) * 10
            }
        };
    }

    useEffect(() => {
        const trendData = getTrendData(selectedRegion, "");
        // console.log("TREND DATA", trendData);
        setTrendStartYear(trendData.past.year);
        setTrendEndYear(trendData.present.year);
        setTrendStartValue(trendData.past.value);
        setTrendEndValue(trendData.present.value);
        const percent = ((trendData.present.value - trendData.past.value) / trendData.past.value) * 100;
        const max100ifInfinity = percent === Infinity ? 100 : percent;
        setTrendPercent(Math.round(max100ifInfinity * 100) / 100);
    }, [selectedRegion]);


    /*
     * RAL - Retribuzione annua lorda
     */
    const [ral, setRal] = useState(selectedJob.sectors[0].ral);
    const [jobsDataReferenceYear, setJobsDataReferenceYear] = useState(2024);

    function getRalMinMax() {
        let dataFound = false;
        let min = Number.MAX_SAFE_INTEGER;
        let max = Number.MIN_SAFE_INTEGER;
        jobSectors.forEach((el) => {
            el.ral.split("-").forEach((r) => {
                let n = Number.parseInt(r.replace(".", ""));
                if (n < min) {
                    min = n;
                    dataFound = true;
                }
                if (n > max) {
                    max = n;
                    dataFound = true;
                }
            });
        });
        if (dataFound) {
            if (min === max) {
                return [
                    min
                ];
            } else {
                return [
                    min,
                    max
                ];
            }
        } else {
            return null;
        }
    }

    useEffect(() => {
        const ralMinMax = getRalMinMax();
        if (ralMinMax) {
            setRal(ralMinMax[0]);
        }
    }, [selectedRegion]);



    /*
     * STUDI E COMPETENZE - BigBarGraph
     */
    const ITS_PERCENTAGE_THRESHOLD = 5; // as used in original source
    const [studyPathsData, setStudyPathsData] = useState([]);
    const [studyPathValues, setStudyPathValues] = useState([]);
    const [studyPathLabels, setStudyPathLabels] = useState([]);

    var TitoliStudio;
    (function (TitoliStudio) {
        TitoliStudio[TitoliStudio["OBBLIGO"] = 0] = "OBBLIGO";
        TitoliStudio[TitoliStudio["FORMAZIONE_PROFESSIONALE"] = 2] = "FORMAZIONE_PROFESSIONALE";
        TitoliStudio[TitoliStudio["DIPLOMA_SECONDARIO"] = 3] = "DIPLOMA_SECONDARIO";
        TitoliStudio[TitoliStudio["ITS"] = 4] = "ITS";
        TitoliStudio[TitoliStudio["TITOLO_UNIVERSITARIO"] = 5] = "TITOLO_UNIVERSITARIO";
    })(TitoliStudio || (TitoliStudio = {}));

    function getStudyPathsData() {
        const getVal = (titolo) => {
            const sumAll = Number(jobSectors.reduce((previousValue, currentValue, currentIndex, array) => {
                previousValue += currentValue.studyLevel.reduce((previousValue1, currentValue1) => {
                    previousValue1 += Number(currentValue1);
                    return previousValue1;
                }, 0);
                return previousValue;
            }, 0));
            const data = jobSectors.reduce((previousValue, currentValue, currentIndex, array) => {
                previousValue.sum += Number(currentValue.studyLevel[titolo]);
                previousValue.count++;
                if (currentIndex === array.length - 1) {
                    previousValue.avg = 100 * previousValue.sum / sumAll;
                }
                return previousValue;
            }, {
                sum: 0,
                count: 0,
                avg: 0
            });
            return Math.floor(data.avg);
        };
        const itsValue = getVal(TitoliStudio.ITS);
        return [
            {
                label: "Obbligo",
                value: getVal(TitoliStudio.OBBLIGO)
            },
            {
                label: "Formazione professionale",
                value: getVal(TitoliStudio.FORMAZIONE_PROFESSIONALE)
            },
            {
                label: "Diploma secondario",
                value: getVal(TitoliStudio.DIPLOMA_SECONDARIO)
            },
            ...itsValue >= ITS_PERCENTAGE_THRESHOLD ? [
                {
                    label: "ITS",
                    value: itsValue
                }
            ] : [],
            {
                label: itsValue >= ITS_PERCENTAGE_THRESHOLD ? "Titolo Universitario" : "Titolo Universitario o ITS",
                value: itsValue >= ITS_PERCENTAGE_THRESHOLD ? getVal(TitoliStudio.TITOLO_UNIVERSITARIO) : getVal(TitoliStudio.TITOLO_UNIVERSITARIO) + itsValue
            },
        ];
    }

    useEffect(() => {
        setStudyPathsData(getStudyPathsData());
        setStudyPathValues(studyPathsData.map((el) => el.value));
        setStudyPathLabels(studyPathsData.map((el) => el.label));
    }, [selectedRegion]);



    /*
     * COMPETENZE RICHIESTE - BubbleGraph
     */
    const [requiredSkillsData, setRequiredSkillsData] = useState([]);

    var Skills;
    (function (Skills) {
        Skills[Skills["cpt_01"] = 0] = "cpt_01";
        Skills[Skills["cpt_02"] = 1] = "cpt_02";
        Skills[Skills["cpt_03"] = 2] = "cpt_03";
        Skills[Skills["cpt_04"] = 3] = "cpt_04";
        Skills[Skills["cpt_05"] = 4] = "cpt_05";
        Skills[Skills["cpt_06"] = 5] = "cpt_06";
        Skills[Skills["cpt_07"] = 6] = "cpt_07";
        Skills[Skills["cpt_08"] = 7] = "cpt_08";
        Skills[Skills["cpt_09"] = 8] = "cpt_09";
        Skills[Skills["cpt_10"] = 9] = "cpt_10";
        Skills[Skills["cpt_11"] = 10] = "cpt_11";
        Skills[Skills["cpt_12"] = 11] = "cpt_12";
    })(Skills || (Skills = {}));

    function useStudyAndSkills(sectors) {
        const skillsData = () => {
            const skillsData2 = [];
            let totalEntries = 0;

            sectors.forEach((sector) => {
                totalEntries += Number(sector.entries[selectedRegion]);

                sector.skills.forEach((currentSkill, index) => {
                    if (skillsData2[index]) {
                        skillsData2[index].entries += Number(currentSkill);
                    }
                    else {
                        const skill = skillsJsonData.find((s) => s.id === Skills[index]);
                        skillsData2[index] = {
                            name: skill ? skill.label : undefined,
                            hidden: skill ? skill.hidden : undefined,
                            entries: Number(currentSkill),
                        };
                    }
                });
            });

            const toReturn = skillsData2.map((value) => ({
                name: value.name,
                hidden: value.hidden,
                difficulty: value.entries / totalEntries > 0.8
                    ? 2
                    : value.entries / totalEntries > 0.5
                        ? 1
                        : 0,
            }));

            return toReturn.filter((value) => value.hidden !== true);
        };

        return skillsData();
    };


    useEffect(() => {
        setRequiredSkillsData(useStudyAndSkills(jobSectors));
    }, [selectedRegion, windowWidth]);



    /*
    * RELATED STUDY PATHS
    */
    const [studyPaths, setStudyPaths] = useState([]);

    var FieldTypes;
    (function (FieldTypes) {
        FieldTypes["PROFESSIONAL_TRAINING"] = "PROFESSIONAL_TRAINING";
        FieldTypes["HIGHSCHOOLS"] = "HIGHSCHOOLS";
        FieldTypes["TECHNICAL_INSTITUTES"] = "TECHNICAL_INSTITUTES";
        FieldTypes["COLLEGES_DEGREES"] = "COLLEGES_DEGREES";
        FieldTypes["ITS"] = "ITS";
    })(FieldTypes || (FieldTypes = {}));

    const FieldMappedNames = {
        [FieldTypes.PROFESSIONAL_TRAINING]: "Formazione professionale",
        [FieldTypes.HIGHSCHOOLS]: "Licei",
        [FieldTypes.TECHNICAL_INSTITUTES]: "Istituti tecnici",
        [FieldTypes.COLLEGES_DEGREES]: "Lauree",
        [FieldTypes.ITS]: "ITS"
    };

    useEffect(() => {
        const retrivedPaths = [];
        const jobFieldToShow = fieldInJobData.filter((jobField) => jobField.jobId === parseInt(selectedJob.fourDigitCode) && jobField.includeInJob);

        jobFieldToShow.forEach((jobField) => {
            const fieldId = jobField.fieldId;
            // console.log(fieldId);
            const fieldInfo = FieldsData.find((field) => parseInt(field.code) === fieldId);
            if (!fieldInfo) return;
            retrivedPaths.push({
                name: fieldInfo.title,
                studyLevel: FieldMappedNames[fieldInfo.type],
                href: fieldInfo.slug,
            });
        });
        setStudyPaths(retrivedPaths);
    }, []);

    return (
        <>
            <div className="container py-3">
                <div className="row">
                    <a href='/professioni' className="col-12 d-flex align-items-center">
                        <Icon.ArrowLeftShort size={30} className='text-primary mw-icon me-2 ms-n1' />
                        <span className='text-uppercase fs-6 fw-bold'>Indietro</span>
                    </a>
                </div>
            </div>
            <div className='bg-primary page-title-row position-sticky' style={{ top: "4.5rem", zIndex: 4, boxShadow: "-4px 14px 20px 0px #00000026" }}>
                <div className="container py-4">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="mb-0 text-white">{selectedJob.jobName}</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mb-5 mt-5 pt-4">
                <div className="row mb-5">
                    <div className="col-12">
                        <h2 className="h3 fw-bold mb-4 mb-lg-5">Descrizione</h2>
                        <p className='fs-5 lh-base'>
                            {selectedJob.description}
                        </p>
                    </div>
                </div>
                {selectedJob.simpleJobs.length > 0 && (<div className="row mb-5">
                    <div className="col-12">
                        <p className="fs-4 fw-bold mb-4">Questa professione contiene le seguenti figure</p>
                        <p className='fs-5 lh-base'>
                            {selectedJob.simpleJobs.map((job, index) => (
                                <span className='d-block' key={index}>- {job}</span>
                            ))}
                        </p>
                    </div>
                </div>)}
                <div className="row justify-content-lg-start mb-5 pb-5 d-flex align-items-stretch">
                    {selectedJob.fiveDigitJobs.map((subJob, index) => (
                        <div className='col-12 col-md-4 mb-4 mt-5' key={index}>
                            <div className='card border-0 bg-100 h-100 rounded-3'>
                                <div className='card-body d-flex flex-column justify-content-between'>
                                    <div className='main'>
                                        <h3 className='h5 fw-bold mb-4'>{subJob.name}</h3>
                                    </div>
                                    <div className='info'>
                                        <p className='mb-5'>
                                            {subJob.description}
                                        </p>
                                    </div>
                                    {subJob.fiveDigitCode && (
                                        <a
                                            href={"https://www.inapp.gov.it/professioni/scopri-professioni/scheda/?keyword=" + subJob.fiveDigitCode.toString().split('').join('.') + "&P3=2"}
                                            target='_blank'
                                            className="btn btn-primary rounded-1 px-4 py-3 fw-bold fs-5">
                                            Approfondisci
                                        </a>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {youngJob !== null && findingDifficulty !== null && trendPercent !== null && ral !== null && (
                <div className='position-relative'>
                    <div className='bg-100 top-0 z-3'>
                        <div className='container'>
                            <div className="row pt-5 pb-5 border-primary justify-content-center bg-100">
                                <div className="col-12 d-flex align-items-center justify-content-center">
                                    <h2 className="h4 fw-bold mb-4 text-center">Scegli una regione</h2>
                                </div>
                                <div className="col-12 col-sm-8 col-md-6 col-lg-4 d-flex align-items-center justify-content-center">
                                    <select
                                        id="change-region"
                                        defaultValue={20}
                                        name="regioni-italia"
                                        title='Scegli una regione'
                                        onChange={(e) => setSelectedRegion(Number(e.target.value))}
                                        className="border-primary border rounded-3 form-select-sm fw-semibold w-100"
                                    >
                                        <option value="0">Piemonte</option>
                                        <option value="1">Valle d'Aosta</option>
                                        <option value="2">Lombardia</option>
                                        <option value="3">Trentino-Alto Adige</option>
                                        <option value="4">Veneto</option>
                                        <option value="5">Friuli-Venezia Giulia</option>
                                        <option value="6">Liguria</option>
                                        <option value="7">Emilia-Romagna</option>
                                        <option value="8">Toscana</option>
                                        <option value="9">Umbria</option>
                                        <option value="10">Marche</option>
                                        <option value="11">Lazio</option>
                                        <option value="12">Abruzzo</option>
                                        <option value="13">Molise</option>
                                        <option value="14">Campania</option>
                                        <option value="15">Puglia</option>
                                        <option value="16">Basilicata</option>
                                        <option value="17">Calabria</option>
                                        <option value="18">Sicilia</option>
                                        <option value="19">Sardegna</option>
                                        <option value="20">Italia</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='bg-100 mb-5'>
                        <div className='container position-relative'>
                            <div className="row">

                                <div className="mh-50vh col-12 col-lg-6 py-5 px-4 px-lg-5 custom-border-lg-end border-primary">
                                    <h2 className="h4 fw-bold mb-4 mb-lg-5">
                                        Posizioni di lavoro offerte dalle imprese
                                    </h2>
                                    <h3 className="h5 fw-semibold mb-3">
                                        Quante offerte di lavoro sono aperte ai giovani?
                                    </h3>
                                    <p className='fs-5 lh-base'>
                                        La percentuale di posizioni aperte in cui l'impresa richiede persone con meno di 30 anni sul totale delle entrate della professione
                                    </p>
                                    {(youngJob !== null && youngJob !== undefined && youngJob !== '' && youngJob >= 0 && !isNaN(youngJob)) ?
                                        (<PieProgressGraph percent={youngJob} />)
                                        : (<p className='alert alert-info fw-normal'>Non sono disponibili dati sulle offerte di lavoro aperte ai giovani di questa selezione</p>)
                                    }
                                </div>
                                <div className="mh-50vh col-12 col-lg-6 py-5 px-4 px-lg-5 border-primary under-lg-border-bottom">
                                    <h2 className="h4 fw-bold mb-4 mb-lg-5">Retribuzione</h2>
                                    <p className='fs-5 lh-base pe-md-5 pe-4'>
                                        La retribuzione annua lorda media per questa professione è di circa:
                                    </p>
                                    {(ral !== null && ral !== undefined && ral !== '' && ral >= 0 && !isNaN(ral)) ?
                                        (<>
                                            <h3 className='bg-primary px-4 py-3 d-inline-block text-white'>
                                                {ral.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} €
                                            </h3>
                                            <p className='alert alert-info fw-normal mt-lg-5 mt-4'>
                                                Il valore è espresso in termini di RAL ovverosia di Retribuzione Annua Lorda, ed è una media delle retribuzioni lorde pagate dalle aziende o dalle organizzazioni per i mestieri che fanno parte di questa area professionale.
                                                Il valore è calcolato sull'intero territorio nazionale. Il dato riportato è riferito all'anno {jobsDataReferenceYear - 2}, fonte INPS.
                                            </p>
                                        </>)
                                        : (<p className='alert alert-info fw-normal'>Non sono disponibili dati sulla retribuzione annua lorda di questa selezione</p>)
                                    }
                                </div>
                            </div>


                            <div className="row">
                                <div className="mh-50vh col-12 col-lg-6 py-5 px-4 px-lg-5 custom-border-lg-end border-primary">
                                    <h2 className="h4 fw-bold mb-4 mb-lg-5">Trend occupazionale</h2>
                                    {(trendPercent !== null && trendPercent !== undefined && trendPercent !== '' && !isNaN(trendPercent)) ?
                                        (<>
                                            <h3 className="h5 fw-semibold mb-3">
                                                {/* Dal {trendStartYear} il numero delle offerte di lavoro è {`${trendPercent >= 0 ? 'cresciuto del' : 'diminuito del'}`} <span className='fw-bold'>{`${trendPercent > 0 ? '+' : ''}`}{trendPercent}%</span> */}
                                                Il numero delle posizioni di lavoro offerte dalle imprese è in {`${trendPercent >= 0 ? 'aumento' : 'diminuzione'}`}
                                            </h3>
                                            <TrendBarGraph
                                                startXValue={trendStartYear}
                                                endXValue={trendEndYear}
                                                startValue={trendStartValue}
                                                endValue={trendEndValue}
                                                percent={trendPercent}
                                                united={true}
                                                description={`Il numero delle posizioni di lavoro offerte dalle imprese è in ${trendPercent >= 0 ? 'aumento' : 'diminuzione'}`}
                                            />
                                        </>)
                                        : (<p className='alert alert-info fw-normal'>Non sono disponibili dati sul trend occupazionale di questa selezione</p>)
                                    }
                                </div>
                                <div className="mh-50vh col-12 col-lg-6 py-5 px-4 px-lg-5">
                                    <h2 className="h4 fw-bold mb-4 mb-lg-5">Difficoltà di reperimento</h2>
                                    <h3 className="h5 fw-semibold mb-3">
                                        Difficoltà delle aziende nel trovare candidati adatti
                                    </h3>
                                    {(findingDifficulty !== null && findingDifficulty !== undefined && findingDifficulty !== '' && findingDifficulty >= 0 && !isNaN(findingDifficulty)) ?
                                        (<>
                                            <p className='fs-5 lh-base mb-4'>
                                                Le aziende che assumono per questa professione dichiarano di avere una
                                                difficoltà <span className='fw-semibold'>{findingDifficulty < 30 ? 'bassa' : findingDifficulty < 70 ? 'media' : findingDifficulty < 80 ? 'medio-alta' : 'alta'}</span> nel trovare candidati
                                                adatti.
                                                {findingDifficulty > 70
                                                    ? (<span><br />Quindi, se dovessi scegliere questo ambito, preparandoti nel modo adatto potresti trovare lavoro senza grandi difficoltà.</span>)
                                                    : (<span><br />Con la giusta preparazione e il giusto CV potresti avere buone possibilità di collocarti in questo ambito.</span>)
                                                }
                                            </p>
                                            <ProgressLine percent={findingDifficulty} />
                                        </>)
                                        : (<p className='alert alert-info fw-normal'>Non sono disponibili dati sulla difficoltà delle aziende nel trovare candidati adatti di questa selezione</p>)
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            )}
            <div className='container py-5'>
                <div className='row'>
                    <div className="col-12">
                        <h2 className="h3 fw-bold mb-4">Studi e competenze</h2>
                        <h3 className="h5 fw-semibold mb-3">Livello di studio richiesto dalle aziende</h3>
                    </div>
                    <div className='col-12 pt-5'>
                        {(studyPathLabels !== null && studyPathLabels !== undefined && studyPathLabels.length > 0) ?
                            (<>
                                <BigBarGraph labels={studyPathLabels} values={studyPathValues} />
                            </>)
                            : (<p className='alert alert-info fw-normal'>Non sono disponibili dati sul trend occupazionale di questa selezione</p>)
                        }
                    </div>
                </div>
                <div className='row mt-5'>
                    <div className="col-12 mt-5">
                        <h3 className="h5 fw-semibold mb-3">Livello delle competenze richieste dalle aziende</h3>
                    </div>
                    <div className='col-12 pt-3 pt-lg-4'>
                        {(requiredSkillsData.length > 0) ?
                            (
                                // <BubbleGraph data={requiredSkillsData} useLabels={true} />
                                console.log(requiredSkillsData),
                                <HorizontalBarGraph data={requiredSkillsData} />
                            )
                            : (<p className='alert alert-info fw-normal'>Non sono disponibili dati sul trend occupazionale di questa selezione</p>)
                        }
                    </div>
                </div>
                {studyPaths.length > 0 && (
                    <div className='row mt-5'>
                        <div className="col-12 mt-5">
                            <h3 className="h5 fw-semibold mb-3">I principali percorsi di studio collegati</h3>
                        </div>
                        <div className='col-12 pt-5 pb-4 bg-100 px-lg-4 rounded-3'>
                            {studyPaths.map((study, index) => (
                                <Link
                                    key={index}
                                    className='text-decoration-none w-100 d-flex align-items-center justify-content-between mb-5 mb-lg-4 fw-semibold'
                                    to={`/percorsi-di-studio/${study.href}`}
                                >
                                    <span className='d-flex flex-column flex-lg-row w-100'>
                                        <p className='mb-0 fs-4 pe-4 study-related-path-name'>{study.name}</p>
                                        <p className='mb-0 fs-6 text-uppercase text-dark pe-4'>{study.studyLevel}</p>
                                    </span>
                                    <Icon.ArrowRightShort size={40} className='text-primary mw-icon' />
                                </Link>
                            ))}
                        </div>
                    </div>
                )}
            </div>
            <OrientaCta />
        </>
    );
};

export default DettaglioProfessione;
